<template>
  <div class="calc">
    <TEXConfigurator />
  </div>
</template>

<script>
import TEXConfigurator from '@/components/TEXConfigurator.vue'
export default {
  components: {
    TEXConfigurator
  },
  name: 'calc',
}
</script>
